import React from "react";
import { Link } from "react-router-dom";
import "./ToggleMenu.css";
import { socialprofils } from "../../../utils/constant";

export default function ToggleMenu({ isActive, handleToggle }) {
  return (
    <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
      <div className="bg__menu h-100">
        <div className="menu__wrapper">
          <div className="menu__container p-3">
            <ul className="the_menu">
              <li className="menu_item ">
                <Link onClick={handleToggle} to="/" className="my-3">
                  Home
                </Link>
              </li>
              <li className="menu_item">
                <Link onClick={handleToggle} to="/portfolio" className="my-3">
                  {" "}
                  Portfolio
                </Link>
              </li>
              <li className="menu_item">
                <Link onClick={handleToggle} to="/about" className="my-3">
                  About Me
                </Link>
              </li>
              <li className="menu_item">
                <Link onClick={handleToggle} to="/contact" className="my-3">
                  {" "}
                  Contact Me
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
        <div className="d-flex">
          <a href={socialprofils.linkedin}>LinkedIn</a>
          <a href={socialprofils.github}>Github</a>
          <a href={socialprofils.facebook}>Facebook</a>
        </div>
        <p className="copyright m-0">Made with ❤️ by Sibasis Padhi</p>
      </div>
    </div>
  );
}
