import React, { useState } from "react";
import "./Header.css";
import { VscGrabber, VscClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import ToggleMenu from "../Navigation/ToggleMenu";
import Themetoggle from "../Theme/ThemeToggle";
import { logo_url } from "../../../utils/constant";

export default function Header() {
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  return (
    <header className="fixed-top site__header">
      <div className="d-flex align-items-center justify-content-between">
        <Link className="navbar-brand nav_ac" to="/">
          <img src={logo_url} alt="My Logo" style={{ height: "30px" }} />
        </Link>
        <div className="d-flex align-items-center">
          <Themetoggle />
          <button className="menu__button  nav_ac" onClick={handleToggle}>
            {!isActive ? <VscClose /> : <VscGrabber />}
          </button>
        </div>
      </div>
      <ToggleMenu isActive={isActive} handleToggle={handleToggle} />
    </header>
  );
}
