const logo_url = "../images/Mylogo.png";
const meta = {
  title: "Sibasis Padhi",
  description:
    "I’m Sibasis Padhi Full Stack Devloper & Digital Marketing Analyst from India",
};

const introduction = {
  title: "I am Sibasis Padhi",
  animated: {
    first: "Full Stack Developer",
    second: "Digital Marketing Analyst",
  },
  description:
    "Full Stack Developer with 6+ years of hands-on experience designing, developing, and implementing applications and solutions using a range of technologies and programming languages.",
  my_img_url: "../images/MyImage.JPG",
};

const aboutMe = {
  title: "About my self",
  aboutme:
    "Full Stack Developer with 6+ years of hands-on experience designing, developing, and implementing applications and solutions using a range of technologies and programming languages. Seeking to leverage broad development experience and hands-on technical expertise in a challenging role as a Full Stack Developer.",
};
const worktimeline = [
  {
    jobtitle: "Full Stack Developer",
    where: "Cintal Inc.",
    date: "2023",
  },
  {
    jobtitle: "Full Stack Developer",
    where: "Simply Potent",
    date: "2022",
  },
  {
    jobtitle: "frontend Developer & Digital Marketing Analyst",
    where: "Guarented Rentals Pvt Ltd.",
    date: "2020",
  },
  {
    jobtitle: "Full Stack Developer",
    where: "TechnoVista Solutions",
    date: "2017",
  },
  {
    jobtitle: "Electrical Engineer",
    where: "United Engneering Syndicate",
    date: "2015",
  },
];

const skills = [
  {
    name: "HTML",
    value: 95,
  },
  {
    name: "CSS",
    value: 95,
  },
  {
    name: "Javascript",
    value: 95,
  },
  {
    name: "ReactJs",
    value: 90,
  },
  {
    name: "NodeJs",
    value: 90,
  },
  {
    name: "JSON",
    value: 90,
  },
  {
    name: "SQL",
    value: 90,
  },
  {
    name: "MongoDB",
    value: 90,
  },
  {
    name: "AWS",
    value: 90,
  },
  {
    name: "RESTfulAPI",
    value: 90,
  },
  {
    name: "GIT",
    value: 90,
  },
  {
    name: "PHP",
    value: 90,
  },
  {
    name: "Bootstrap",
    value: 90,
  },
  {
    name: "Wordpress",
    value: 90,
  },
  {
    name: "Shopify",
    value: 90,
  },
  {
    name: "Docker",
    value: 90,
  },
  {
    name: "Linux OS",
    value: 90,
  },
  {
    name: "SEO",
    value: 90,
  },
  {
    name: "SEM",
    value: 90,
  },
];

const ProfessionalExperience = [
  {
    companyName: "Cintal Technologies Private Limited.",
    duration: "May 2022 - Oct 2023",
    position: "Full Stack Developer",
    domain: "Healthcare",
    description:
      "\n* Developed a Responsive Website from scratch, leveraged HTML, CSS, JavaScript, and ReactJS as the frontend, tailored specifically for the healthcare industry through performance optimization techniques, and deep focus on enhanced user experience.\n\n* Engineered backend services, utilized NodeJs, ExpressJs, and MongoDB to create robust RESTful APIs which is seamlessly integrated with the frontend, mobile app, and IoT devices, handling over 1000 requests per second.\n\n* Orchestrated a comprehensive communication system, incorporated Video and Audio Call SDKs via Socket integration, meeting critical project requirements for real-time interaction in telehealth applications.\n\n * Utilized MongoDB, as the core database solution, enabling agile data storage and scalability supporting a rapid data growth, with a 20% increase in data volume over six months.\n\n * Established and managed AWS infrastructure, including an EC2 instance for backend services and an S3 bucket for frontend hosting ensuring a high availability and scalability.\n\n * Collaborated with project managers to understand client requirements and formulate technical solutions, ensuring peak performance, reliability, and accessibility through clear of technical and design specifications.\n\n * Facilitated knowledge-sharing sessions, by coordinating and leading the effort of team progress and overall project efficiency enhancement.",
  },
  {
    companyName: "Simply Potent",
    duration: "Jan 2020 - April 2022",
    position: "Full Stack Developer",
    domain: "Healthcare Ecommerce",
    description:
      "\n* Full-Stack Development, leveraged PHP technologies for the end-to-end development of enterprise-level applications, collaborating across global teams.\n\n * Leadership in Complex Application Design, led the design, programming, testing, and implementation of complex computing applications, relying on proficiency in PHP.I have developed application architecture integrating PHP proficiency software with delivery subsystems.\n\n* API Development, demonstrated proficiency in modeling and implementing API services using SOAP and RESTful methodologies using Node.js.\n\n * UI Development and Front-End Expertise, showcased strong UI development skills with JavaScript frameworks like ReactJS. Proficient in HTML,CSS, jQuery, AJAX, Bootstrap, JavaScript, JSON, XML, for driving a seamless user experience.\n\n * Database and Data Modeling, applied expertise in SQL, including data modeling. Ensured seamless integration with overall system architecture using standard IT lifecycle methodologies.\n\n * Agile Methodologies, exhibited working exposure to Agile methodologies, including Scrum and SAFe, and a keen understanding of cloud technologies, aligning development processes with industry standards.\n\n * Application Integration, proficiently managed application integration and migration, both with Commercial Off-The-Shelf (COTS) and In-House applications, through middleware and SOA solutions.\n\n * High-Volume, Distributed Applications, successfully developed and deployed high-volume, multi-tiered, distributed business-critical applications, showcasing a strong work ethic, exceptional communication skills, and effective time management.\n\n * Technical Mentorship, served as a technical mentor for team members, sharing knowledge and fostering skill development within the team.\n\n * Global Collaboration, exhibited excellent presentation and communication skills while working effectively in globally distributed teams and engaging in stakeholder meetings.",
  },
  {
    companyName: "Guarented Rentals Private Limited.",
    duration: "May 2018 - Jan 2020",
    position: "Fronted Developer & Digital Marketing Analyst",
    domain: "Ecommerce",
    description:
      "\n* Developed a Responsive AdminPanel along with email tempaltes using ReactJS.\n\n* Multi-Platform Advertising Campaigns, developed and executed highly effective advertising campaigns for retail clients across multiple platforms, boosting brand visibility and engagement.\n\n* Marketing Team Leadership, led a dynamic marketing team at a fast-growing eCommerce company, ensuring alignment with business goals.\n\n* Digital Strategy, executed strategic digital approaches, consistently outperforming competitors in engagement metrics.\n\n* SEO Optimization, employed search engine optimization techniques to improve organic search rankings and website visibility.\n\n* Social Media Growth Strategies, identified opportunities and developed actionable strategies for expanding the social media follower base using promotions, contests, polls, ads, surveys, and referral programs.\n\n* Cross-Functional Collaboration, collaborated effectively across teams to execute high-profile, multi-channel digital campaigns.\n\n* Analytics Proficiency, demonstrated outstanding proficiency in Google Analytics for data-driven decision-making and continuous improvement.\n\n* Continuous Learning and Trend Analysis, stayed updated on the latest digital marketing and SEO trends through daily online research and networking, applying current insights for innovative strategies.",
  },
  {
    companyName: "TechnoVista Solutions India Private Limited.",
    duration: "Sept 2016 - July 2017",
    position: "Full Stack Developer",
    domain: "Banking, Insurance",
    description:
      "\n* Supported the development of a web-based Insurance Management System, which featured CEO, Branch Manager, Agent, and Customer modules, each with distinct access rights and sub-modules.\n\n* Orchestrated the design process and conducted comprehensive unit testing, for the CEO, Branch Manager, and Agent modules, ensuring streamlined and user-friendly insurance operations.\n\n* Engineered an intuitive application, that simplifies the entire insurance process, optimizing management efficiency and enhancing the user experience.\n\n* Implemented robust security measures, including username and password authentication, to fortify data protection and ensure user privacy.\n\n* Managed ongoing system maintenance and provided continuous support, actively driving enhancements for improved functionality and heightened user satisfaction.",
  },
];

const dataportfolio = [
  {
    img: "../images/SwiggyClone.gif",
    description:
      "Online Food Ordering Responsive App made with ReactJS,TailwindCss,NodeJs,ExpressJs,MongoDB",
    link: "#",
  },
  {
    img: "../images/simplyPotent.gif",
    description: "Healthcare Ecommerce Website",
    link: "https://simply-potent.com/",
  },
  {
    img: "../images/OctorProject.gif",
    description:
      "Doctor & Patient online consulation Application with video and chat option",
    link: "https://www.octor-connect.com/",
  },
  {
    img: "../images/simcosteel.gif",
    description: "Ecommerce storage and supply chain management.",
    link: "https://simcosteel.in/",
  },
  {
    img: "../images/ApiDashboard.png",
    description:
      "Analytics Dashboard with ReactJS,NodeJs and MongoDB with Filter options.",
    link: "#",
  },
  {
    img: "../images/Netflix-with-GPT.gif",
    description: "Netflix Clone With GPT Search Addon with ReactJS & NodeJs",
    link: "#",
  },
  {
    img: "../images/EcommerceTemplate.gif",
    description:
      "Ecommerce Template with Stripe,Paypal,Razorpay payemnt Integration.",
    link: "#",
  },
];

const contactConfig = {
  YOUR_EMAIL: "sibasis@outlook.com",
  YOUR_FONE: "+91-9437197776",
  description:
    "For more details related to work or any Freelancing Project feel free to connect. I will respond within 24 Hours.",
  YOUR_SERVICE_ID: "service_krtorj4",
  YOUR_TEMPLATE_ID: "template_zsvyerk",
  YOUR_USER_ID: "Ux9onRb_gW75uDcF-",
};

const socialprofils = {
  github: "https://github.com/sibasis-padhi",
  facebook: "https://facebook.com",
  linkedin: "https://www.linkedin.com/in/sibasispadhi/",
  twitter: "https://twitter.com",
};
export {
  meta,
  aboutMe,
  dataportfolio,
  worktimeline,
  skills,
  ProfessionalExperience,
  introduction,
  contactConfig,
  socialprofils,
  logo_url,
};
